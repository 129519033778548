import LinkTo from 'components/linkTo';
import { FORMATTED_DEFAULT_LOCALE } from 'constants/i18n';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import { i18nLocaleFormatter } from 'util/i18nLocaleFormatter';

import styles from './style.module.scss';

const Custom404 = () => {
  const { t } = useTranslation();

  return (
    <>
      <NextSeo title="Page not found" description="Page not found" />
      <div className={`mt72 bg-grey-200 ${styles.container}`}>
        <Image
          className={styles.img}
          src="/static/404/feathers.png"
          alt={t('page.404.image.alt')}
          height={130}
          width={212}
        />
        <h2 className="tc-grey-900 mt32 p-h2">{t('page.404.title')}</h2>
        <p className="p-p mt8 ta-center wmx5">{t('page.404.description')}</p>
        <LinkTo
          href="/"
          className={`p-btn--secondary mt32 wmn3 ${styles.button}`}
        >
          {t('page.404.return.action')}
        </LinkTo>
      </div>
    </>
  );
};

export default Custom404;

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(
      i18nLocaleFormatter(locale) || FORMATTED_DEFAULT_LOCALE,
      ['common']
    )),
  },
});
